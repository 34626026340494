import * as React from "react";
import Seo from "../components/seo";
import LayoutWithSidebar from "../layout/withSidebar";

const NotFoundPage = () => {
  const pageTitle = "The HTTP 404";
  const pageSubtitle = "Page Not Found";

  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo title="404: Not Found" />
      <h1 className="text-primary">Mr. Stark, I don't feel so good</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </LayoutWithSidebar>
  );
};

export default NotFoundPage;
